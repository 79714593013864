import { api } from 'boot/axios.js';
import { DateTime } from 'luxon';
import { defineStore } from 'pinia';
import ClientService from 'src/services/client-service.js';
import { useAuthStore } from 'stores/auth-store.js';
import { useTagStore } from 'stores/tag-store.js';
import { useTaskStore } from 'stores/task-store.js';

/**
 * @typedef ClientStoreType
 * @type { actions | getters | state | import('pinia').Store }
 */
/**
 * @typedef useClientStore
 * @type function
 * @param {import('pinia').Pinia | null | undefined} [pinia]
 * @param {import('pinia').StoreGeneric} [hot]
 * @returns ClientStoreType
 */

/**
 * @type useClientStore
 */
export const useClientStore = defineStore('client', {
    state: () => ({
        _clientsDirty: false,
        _clientsLoadedAt: null,
        _clients: new Set(),
        _projects: new Map(),
        currentClient: null,
        clientFilters: {
            label: ''
        }
    }),
    getters: {
        clients() {
            return Array.from(this._clients);
        },

        hasClients() {
            return this._clients.size > 0;
        },

        totalClients() {
            return this._clients.size;
        },

        sortedClients() {
            return this.clients.sort((clientA, clientB) => {
                if (clientA.tag?.label < clientB.tag?.label) return -1;
                if (clientA.tag?.label > clientB.tag?.label) return 1;
                return 0;
            });
        },

        hasClientFilters() {
            return this.clientFilters.label.length > 0;
        },

        hasClientLabelFilter() {
            return this.clientFilters.label;
        },

        filteredClients() {
            return this.sortedClients.filter(client => {
                return !this.hasClientFilters
                    || (this.hasClientLabelFilter && client.label.toLowerCase().includes(this.clientFilters.label.toLowerCase()));
            });
        },

        totalFilteredClients() {
            return this.filteredClients.length;
        }
    },
    actions: {
        resetAllClientFilters() {
            this.clientFilters.label = '';
        },

        // Transforms the client to a tag by inverting the object structure.
        transformClientToTag(client) {
            return (({ tag, ...client }) => ({ ...tag, client: { label: tag.label, ...client } }))(client);
        },

        // Transforms the tag to a client by inverting the object structure.
        transformTagToClient(tag) {
            return (({ client, ...tag }) => ({ ...client, label: tag.label, tag }))(tag);
        },

        clientsInvalidated() {
            return this._clientsDirty
                || !this._clientsLoadedAt
                || DateTime.now().diff(this._clientsLoadedAt, [ 'minutes' ]).minutes >= 5;
        },

        invalidateClients() {
            this._clientsDirty = true;
        },

        setCurrentClient(client) {
            this.currentClient = client;
        },

        findById(clientId) {
            return this.clients.find(c => c.id === clientId);
        },

        appendClients(clients) {
            for (const client of clients) {
                const existingClient = this.clients.find(c => c.id === client.id);
                if (existingClient) {
                    Object.assign(existingClient, client);
                    continue;
                }
                this._clients.add(client);
            }
        },

        mergeClients(clients) {
            for (const client of clients) {
                const existingClient = this.clients.find(c => c.id === client.id);
                if (existingClient) {
                    Object.assign(existingClient, client);
                    continue;
                }
                this._clients.add(client);
            }
            for (const client of this.clients) {
                const existingClient = clients.find(c => c.id === client.id);
                if (!existingClient) {
                    const tagStore = useTagStore();
                    const taskStore = useTaskStore();
                    const tag = tagStore.tags.find(t => t.id === client.tag.id);
                    this._clients.delete(client);
                    if (tag) {
                        tagStore.tags.splice(tagStore.tags.indexOf(tag), 1);
                        taskStore.removeTagFromAllTasks(tag);
                    }
                }
            }
        },

        async loadClient(clientId) {
            if (!useAuthStore().isAuthenticated) {
                return;
            }
            const resource = (await api.get(`/clients/${clientId}`)).data;
            const cachedClient = this.findById(clientId);
            if (cachedClient) {
                Object.assign(cachedClient, resource);
                return cachedClient;
            }
            return this._clients.add(resource);
        },

        async loadClients() {
            if (!useAuthStore().isAuthenticated || !this.clientsInvalidated()) {
                return;
            }
            const clients = await ClientService.getClients();
            this._clients.clear();
            for (const client of clients) {
                this._clients.add(client);
            }
            this._clientsDirty = false;
            this._clientsLoadedAt = DateTime.now();
        },

        async saveClient(client) {
            if (!useAuthStore().isAuthenticated) {
                return;
            }
            const updatedClient = client.id
                ? await ClientService.updateClient(client)
                : await ClientService.createClient(client);
            const existingClient = this.clients.find(c => c.id === updatedClient.id);
            if (existingClient) {
                Object.assign(existingClient, updatedClient);
                return;
            }
            this._clients.add(updatedClient);
            // TODO find a better way to handle this.
            useTagStore().mergeTag(this.transformClientToTag(updatedClient));
        },

        async deleteClient(client) {
            try {
                const tagStore = useTagStore();
                if (client.createdAt) {
                    await ClientService.deleteClient(client.id);
                }
                this._clients.delete(client);
                const tag = tagStore.tags.find(t => t.label === client.tag.label);
                if (tag) {
                    tagStore.tags.splice(tagStore.tags.indexOf(tag), 1);
                    return useTaskStore().removeTagFromAllTasks(tag);
                }
                return [];
            }
            catch (err) {
                throw err;
            }
        },

        async restoreClient(client, taskIds) {
            const tagStore = useTagStore();
            const resource = client.createdAt ? await ClientService.restoreClient(client.id) : client;
            const tag = this.transformClientToTag(resource);
            this._clients.add(resource);
            tagStore.tags.push(tag);
            for (const taskId of taskIds) {
                const task = useTaskStore().findTaskById(taskId);
                if (!task) continue;
                task.tags.push(tag);
            }
        }
    }
});
