import * as Sentry from '@sentry/vue';
import { auth } from 'boot/axios';
import { $event } from 'boot/emitter';
import { DateTime } from 'luxon';
import { defineStore } from 'pinia';
import { useLocalStorageManager } from 'src/composables/local-storage-manager';
import * as AuthService from 'src/services/auth-service.js';
import { openComponentDialog } from 'src/utils/quasar-util.js';
import { useClientStore } from 'stores/client-store.js';
import { useGeoStore } from 'stores/geo-store.js';
import { useInvoiceStore } from 'stores/invoice-store.js';
import { useNotificationStore } from 'stores/notification-store';
import { usePlanStore } from 'stores/plan-store';
import { usePublicInvoiceStore } from 'stores/public-invoice-store';
import { useReportStore } from 'stores/report-store';
import { useSettingsStore } from 'stores/settings-store.js';
import { useTagStore } from 'stores/tag-store.js';
import { useTaskStore } from 'stores/task-store.js';
import { useTaxRateStore } from 'stores/tax-rate-store';
import { useTimerStore } from 'stores/timer-store';
import { computed, defineAsyncComponent, ref } from 'vue';

export const useAuthStore = defineStore('auth', () => {
    const { storeRef } = useLocalStorageManager();

    const providers = ref([]);
    const user = ref(null);
    const userLoadedAt = ref(null);
    const userAccounts = ref([]);
    const promptedLogin = ref(false);
    const returnRoute = storeRef('auth/returnRoute', null);

    const isAuthenticated = computed(() => {
        return !!user.value;
    });

    const isSubscribed = computed(() => {
        return !!user.value?.isSubscribed;
    });

    const isEligibleForFreeTrial = computed(() => {
        return !!user.value?.isEligibleForFreeTrial;
    });

    const hasFullName = computed(() => {
        if (!user.value.fullName) {
            return null;
        }
        const fullName = user.value.fullName?.trim();
        return fullName.length > 0;
    });

    const fullName = computed(() => {
        if (!user.value.fullName) {
            return null;
        }
        const fullName = user.value.fullName.trim();
        return fullName.length > 0 ? fullName : 'Anonymous';
    });

    const hasCountryCode = computed(() => {
        return !!user.value?.countryCode;
    });

    const initials = computed(() => {
        return user.value.fullName?.trim()
            ? user.value.fullName[0].toUpperCase()
            : 'A'; // Anonymous
    });

    const availableProviders = computed(() => {
        const linkedProviders = userAccounts.value.map(a => a.providerId);
        return providers.value.filter(p => !linkedProviders.includes(p));
    });

    async function loadProviders(forceReload = false) {
        if (!forceReload && providers.value.length !== 0) {
            return;
        }
        providers.value = await AuthService.getProviders();
    }

    function purgeStoreStates(persistedOnly = false) {
        Sentry.setUser(null);
        const conditionalStores = [
            useTaskStore
        ];
        const resetStores = [
            useAuthStore,
            useClientStore,
            useGeoStore,
            useInvoiceStore,
            useNotificationStore,
            usePlanStore,
            usePublicInvoiceStore,
            useReportStore,
            useSettingsStore,
            useTagStore,
            useTaxRateStore,
            useTimerStore
        ];
        persistedOnly
            ? conditionalStores.forEach(s => s().$purgePersistedState())
            : conditionalStores.forEach(s => s().$reset());
        resetStores.forEach(s => s().$reset());
        // Detach and unsubscribe any websocket channels
        useTaskStore().unwatchTaskEvents();
    }

    function requestLoginOtp(captcha, email, fullName = undefined) {
        const data = { email, fullName };
        const headers = { 'x-captcha-token': captcha.token };
        if (captcha.hasOwnProperty('id') && captcha.hasOwnProperty('challenge')) {
            headers['x-captcha-id'] = captcha.id;
            headers['x-captcha-challenge'] = captcha.challenge;
        }
        return auth.post('/login', data, { headers });
    }

    async function resendOtp(authJwt) {
        const headers = { 'authorization': 'Bearer ' + authJwt };
        const response = await auth.post('/resend', {}, { headers });
        return response.data.token;
    }

    async function verifyOtp(authJwt, otpToken, email) {
        const data = { email, token: otpToken.toString() };
        const headers = { 'authorization': 'Bearer ' + authJwt };
        await auth.post('/user/verify', data, { headers });
        await loadUser(true);
    }

    async function loadUser(force = false) {
        if (!force && userLoadedAt.value) {
            return;
        }
        try {
            const { locale, ..._user } = await AuthService.getUser();
            user.value = _user;
            if (user.value && (!hasFullName.value || !hasCountryCode.value)) {
                openComponentDialog(defineAsyncComponent(() => import('components/NewUserDialog.vue')));
            }
            Sentry.setUser({ id: _user.id });
            useSettingsStore().mergeSettings({ locale, location: _user.location });
            useSettingsStore().syncLocaleSettingsToStores();
            useTaskStore().watchTaskEvents();
        } catch (err) {
            if (user.value) purgeStoreStates();
        } finally {
            userLoadedAt.value = DateTime.now();
        }
    }

    async function saveUser(_user) {
        const response = await auth.put('/user', _user);
        user.value = response.data;
        if (response.status === 206) {
            AuthService.openOtpDialog(_user.email, response.data.token);
        }
    }

    async function logoutUser() {
        await AuthService.logoutUser();
        purgeStoreStates();
    }

    async function loadUserAccounts(forceReload = false) {
        if (!forceReload && userAccounts.value.length !== 0) {
            return;
        }
        userAccounts.value = await AuthService.getUserAccounts();
    }

    async function deleteUserAccount(accountId) {
        await AuthService.deleteUserAccount(accountId);
        const accountIndex = userAccounts.value.findIndex(a => a.id === accountId);
        userAccounts.value.splice(accountIndex, 1);
    }

    async function requestEmailCode() {
        await AuthService.requestEmailCode();
    }

    async function createSubscriptionCheckoutUrl(planId) {
        const response = await auth.post('/user/subscription', { planId });
        return response.data.url;
    }

    async function getSubscriptionManageUrl() {
        const response = await auth.get('/user/subscription');
        return response.data.url;
    }

    $event.addEventListener('session:logout', () => purgeStoreStates());
    $event.addEventListener('session:expired', () => purgeStoreStates(true));

    return {
        providers,
        user,
        userLoadedAt,
        userAccounts,
        promptedLogin,
        returnRoute,
        isAuthenticated,
        isSubscribed,
        isEligibleForFreeTrial,
        hasFullName,
        fullName,
        hasCountryCode,
        initials,
        availableProviders,
        loadProviders,
        requestLoginOtp,
        resendOtp,
        verifyOtp,
        loadUser,
        saveUser,
        logoutUser,
        loadUserAccounts,
        deleteUserAccount,
        requestEmailCode,
        createSubscriptionCheckoutUrl,
        getSubscriptionManageUrl
    };
});
