import { api } from 'boot/axios.js';

export default class TaskService {

    static sortItemsByLatest(items) {
        if (!items || !Array.isArray(items)) {
            return items;
        }
        return items.sort((a, b) => {
            const getValue = (dateTime) => dateTime ?? Infinity;
            if (getValue(a?.endDate) < getValue(b?.endDate)) return 1;
            if (getValue(a?.endDate) > getValue(b?.endDate)) return -1;
            return 0;
        });
    }

    static findLatestActivity(activities) {
        let latestActivity = null;
        for (const activity of activities) {
            const startDate = activity.startDate ?? Infinity;
            if (!latestActivity || startDate > latestActivity?.startDate) latestActivity = activity;
        }
        return latestActivity;
    }

    static findActivitiesWithinDateRange(task, startDate, endDate) {
        if (!startDate && !endDate) return task.activities;
        if (!startDate || !endDate) throw new Error('Must have a startDate and an endDate specified');
        return task.activities.filter(a => a.isWithinDateRange(startDate, endDate));
    }

    static async createTask(request) {
        return (await api.post('/tasks', {
            summary: request.summary,
            notes: request.notes,
            tags: request.tags,
            activities: request.activities
        })).data;
    }

    static async updateTask(request) {
        return (await api.put(`/tasks/${request.id}`, {
            id: request.id,
            summary: request.summary,
            notes: request.notes,
            tags: request.tags,
            activities: request.activities.map(a => ({
                id: a.isPersisted() ? a.id : null,
                startDate: a.startDate.toISO(),
                endDate: a.endDate ? a.endDate.toISO() : null
            }))
        })).data;
    }

    static async deleteTask(taskId) {
        await api.delete(`/tasks/${taskId}`);
    }

    static async restoreTask(taskId) {
        return (await api.put(`/bin/tasks/${taskId}`)).data;
    }
}
