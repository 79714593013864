import { DateTime } from 'luxon';
import { defaultFinancialYearStartMonth } from 'src/config/app-config';

export const DateRange = {
    TODAY: 'TODAY',
    YESTERDAY: 'YESTERDAY',
    LAST_7_DAYS: 'LAST_7_DAYS',
    LAST_14_DAYS: 'LAST_14_DAYS',
    THIS_WEEK: 'THIS_WEEK',
    LAST_WEEK: 'LAST_WEEK',
    THIS_FORTNIGHT: 'THIS_FORTNIGHT',
    LAST_FORTNIGHT: 'LAST_FORTNIGHT',
    THIS_MONTH: 'THIS_MONTH',
    LAST_MONTH: 'LAST_MONTH',
    THIS_FINANCIAL_YEAR: 'THIS_FINANCIAL_YEAR',
    LAST_FINANCIAL_YEAR: 'LAST_FINANCIAL_YEAR',
    ALL_TIME: 'ALL_TIME',
    CUSTOM: 'CUSTOM'
};

export function convertToDateTime(obj, ...keys) {
    const newObj = Object.assign({}, obj);
    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        newObj[key] = DateTime.fromISO(newObj[key]);
    }
    return newObj;
}

export function isSameDate(dateTimeA, dateTimeB) {
    return dateTimeA.toFormat('yyyy-MM-dd') === dateTimeB.toFormat('yyyy-MM-dd');
}

/**
 * Resolves the date range into a specific time period based on the given date range and current date/time.
 *
 * @param {DateRange} dateRange
 * @param {DateTime} [dateTime=DateTime.now()]
 * @param {object} [options={}]
 * @param {DateTime|Date|string|number|null} options.fortnightStartDate
 * @param {number} [options.financialYearStartMonth=7] - The starting month of the financial year (1-12, default is 7 for July)
 * @returns {{from: null, to: null}|{from: DateTime, to: DateTime}}
 */
export function resolveDateRange(dateRange, dateTime = DateTime.now(), options = {}) {
    switch (dateRange) {
        case DateRange.TODAY:
            return {
                from: dateTime.startOf('day'),
                to: dateTime.endOf('day')
            };
        case DateRange.YESTERDAY:
            return {
                from: dateTime.minus({ days: 1 }).startOf('day'),
                to: dateTime.minus({ days: 1 }).endOf('day')
            };
        case DateRange.LAST_7_DAYS:
            return {
                from: dateTime.minus({ days: 6 }).startOf('day'),
                to: dateTime.endOf('day')
            };
        case DateRange.LAST_14_DAYS:
            return {
                from: dateTime.minus({ days: 13 }).startOf('day'),
                to: dateTime.endOf('day')
            };
        case DateRange.THIS_WEEK:
            return {
                from: dateTime.startOf('week'),
                to: dateTime.endOf('week')
            };
        case DateRange.LAST_WEEK:
            return {
                from: dateTime.minus({ weeks: 1 }).startOf('week'),
                to: dateTime.minus({ weeks: 1 }).endOf('week')
            };
        case DateRange.THIS_FORTNIGHT:
            return getCurrentFortnightDateRange(options?.fortnightStartDate);
        case DateRange.LAST_FORTNIGHT:
            return getLastFortnightDateRange(options?.fortnightStartDate);
        case DateRange.THIS_MONTH:
            return {
                from: dateTime.startOf('month'),
                to: dateTime.endOf('month')
            };
        case DateRange.LAST_MONTH:
            return {
                from: dateTime.minus({ months: 1 }).startOf('month'),
                to: dateTime.minus({ months: 1 }).endOf('month')
            };
        case DateRange.THIS_FINANCIAL_YEAR:
            return getFinancialYearDateRange(dateTime, options.financialYearStartMonth);
        case DateRange.LAST_FINANCIAL_YEAR:
            return getFinancialYearDateRange(dateTime.minus({ years: 1 }), options.financialYearStartMonth);
        default:
            return { from: null, to: null };
    }
}

/**
 * Calculates the date range for the current fortnight from a specified or inferred reference start date. The fortnight
 * always starts on the same weekday as the reference start date, allowing a custom billing cycle.
 *
 * @param {DateTime|Date|string|number|null} referenceStartDate
 * @returns {{from: DateTime, to: DateTime}}
 */
export function getCurrentFortnightDateRange(referenceStartDate = null) {
    referenceStartDate = referenceStartDate
        ? resolveDateTime(referenceStartDate)
        : getFirstMondayOfCurrentYear();
    const currentDate = DateTime.now();
    const weeksFromReference = currentDate.diff(referenceStartDate.startOf('day'), 'weeks').weeks;
    const fortnightCycle = Math.floor(weeksFromReference / 2);
    const from = referenceStartDate.plus({ weeks: fortnightCycle * 2 }).startOf('day');
    const to = from.plus({ days: 13 }).endOf('day');
    return { from, to };
}

/**
 * Resolves the date range for the last fortnight based on a given reference start date. This function first determines
 * the current fortnight's start date using the provided reference date and then calculates the date range for the
 * preceding fortnight.
 *
 * @param {DateTime|Date|string|number|null} referenceStartDate
 * @returns {{from: DateTime, to: DateTime}}
 */
export function getLastFortnightDateRange(referenceStartDate = null) {
    const curFortnightDateRange = getCurrentFortnightDateRange(referenceStartDate);
    const from = curFortnightDateRange.from.minus({ weeks: 2 }).startOf('day');
    const to = curFortnightDateRange.from.minus({ days: 1 }).endOf('day');
    return { from, to };
}

/**
 * Resolves various input types to a Luxon DateTime object.
 *
 * @param {DateTime|Date|string|number} input
 * @returns {DateTime}
 */
export function resolveDateTime(input) {
    if (input instanceof DateTime) return input;
    if (input instanceof Date) return DateTime.fromJSDate(input);
    if (typeof input === 'string') return DateTime.fromISO(input);
    if (typeof input === 'number') return DateTime.fromMillis(input);
    throw new Error('Unsupported type for DateTime conversion');
}

/**
 * Calculates the date of the first Monday of the current year. This function helps align the start of time-based
 * calculations to the first full workweek of the year, ensuring that it always starts on a Monday, regardless of the
 * day the year actually started on.
 *
 * @returns {DateTime}
 */
export function getFirstMondayOfCurrentYear() {
    const startOfYear = DateTime.now().startOf('year');
    let daysToAdd;
    daysToAdd = (8 - startOfYear.weekday) % 7;
    daysToAdd = daysToAdd === 7 ? 0 : daysToAdd;
    return startOfYear.plus({ days: daysToAdd });
}

/**
 * Calculates the date range for the financial year that includes the given date.
 *
 * @param {DateTime} dateTime - The reference date
 * @param {number} [startMonth=defaultFinancialYearStartMonth] - The starting month of the financial year (1-12, default is 7 for July)
 * @returns {{from: DateTime, to: DateTime}}
 */
function getFinancialYearDateRange(dateTime, startMonth = defaultFinancialYearStartMonth) {
    const year = dateTime.year;
    let from;
    let to;
    if (dateTime.month < startMonth) {
        from = DateTime.local(year - 1, startMonth, 1);
        to = DateTime.local(year, startMonth, 1).minus({ days: 1 });
    } else {
        from = DateTime.local(year, startMonth, 1);
        to = DateTime.local(year + 1, startMonth, 1).minus({ days: 1 });
    }
    return { from: from.startOf('day'), to: to.endOf('day') };
}
