import { trackUmamiPageView } from '@jaseeey/vue-umami-plugin';
import { Router } from 'src/router/index.js';

export function removeQueryParams(...params) {
    const newParams = { ...Router.currentRoute.value.query };
    for (const param of params) {
        delete newParams[param];
    }
    Router.replace({ params: newParams });
}

export function joinURLPaths(...segments) {
    return segments.join('/').replace(/(^|[^:])\/+/g, '$1/');
}

/**
 * Handles page view tracking logic based on route changes.
 *
 * @param {Object} targetRoute - The Vue Router route object.
 * @param {Object} options - Configuration options for tracking.
 * @param {string} options.titleSuffixToRemove - Suffix to remove from the title, if any.
 * @param {string} options.maskedTitleKey - Meta key for alternative titles.
 * @param {string} options.maskedUrlKey - Meta key for alternative URLs.
 */
export function trackOnTitleUpdate(targetRoute, options = {}) {
    const pageOptions = getPageOptions(targetRoute, options);
    const titleElement = document.querySelector('title');
    if (titleElement && titleShouldBeObserved(targetRoute, options)) {
        return setupTitleObserver(titleElement, pageOptions);
    }
    trackUmamiPageView(pageOptions);
}

/**
 * Constructs and returns the page options based on the given route and configuration options.
 *
 * @param {Object} route - The Vue Router route object.
 * @param {Object} options - Configuration options for the title and URL.
 * @returns {Object} - Page options with the formatted title and URL for tracking.
 */
function getPageOptions(route, { titleSuffixToRemove = '', maskedTitleKey = 'maskedTitle', maskedUrlKey = 'maskedUrl' }) {
    const titleElement = document.querySelector('title');
    const defaultTitle = titleElement ? document.title : '';
    const title = (route.meta[maskedTitleKey] || defaultTitle).replace(new RegExp(titleSuffixToRemove, 'g'), '');
    const url = route.meta[maskedUrlKey] || joinURLPaths(process.env.VUE_ROUTER_BASE, route.fullPath);
    return { title, url };
}

/**
 * Determines if the title element should be observed for changes.
 *
 * @param {Object} route - The current Vue Router route object.
 * @param {Object} options - Configuration options that include a key indicating if the title is masked.
 * @returns {boolean} - True if the title should be observed; otherwise, false.
 */
function titleShouldBeObserved(route, { maskedTitleKey = 'maskedTitle' }) {
    return !!route?.meta[maskedTitleKey];
}

/**
 * Sets up a MutationObserver to detect changes in the title element and trigger tracking.
 *
 * @param {HTMLElement} titleElement - The title DOM element to observe.
 * @param {Object} pageOptions - Options containing the title and URL to be used for tracking.
 */
function setupTitleObserver(titleElement, pageOptions) {
    const observer = new MutationObserver(mutations => {
        observer.disconnect();
        clearTimeout(timeoutId);
        trackUmamiPageView(pageOptions);
    });
    observer.observe(titleElement, { childList: true, subtree: true, characterData: true });
    const timeoutId = setTimeout(() => {
        console.log('Timeout reached without title change.');
        observer.disconnect();
        trackUmamiPageView(pageOptions);
    }, 5000);
}
